<template>
  <ion-page>
    <ion-content>
      <div class="finger">
        <!-- <img src="@/assets/logo.png" alt="Okalore" class="finger__img" /> -->
        <h1>
          Aupa <strong>{{ user }}</strong>
        </h1>

        <ion-icon
          :icon="fingerPrintOutline"
          color="primary"
          @click="_fingerHandle"
        />
        <p>Aztarnarekin sartu</p>

        <div class="finger__copy">OKALORE VERSION 1.0</div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { Plugins } from "@capacitor/core";
const { BiometricAuth } = Plugins;
const { Storage } = Plugins;
import { onMounted, computed } from "vue";
import { fingerPrintOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Finger",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.state.user);

    onMounted(async () => {
      BiometricAuth.isAvailable()
        .then((res) => {
          console.log(res);
        })
        .catch(async () => {
          await Storage.set({
            key: "_sensor",
            value: JSON.stringify({
              pass: true,
            }),
          });
          router.push("/home");
        });
    });
    const _fingerHandle = async () => {
      const available = await BiometricAuth.isAvailable();
      if (available.has) {
        const authResult = await BiometricAuth.verify({
          reason: "Aztarnarekin sartu",
          title: "Aztarnarekin sartu",
          subTitle: "Autentikazioa beharrezkoa da aurrera jarraitzeko",
          description: "Zure datuak babesteko sartu zure aztarna",
          cancel: "Ezeztatu",
        });
        if (authResult.verified) {
          // success authentication
          await Storage.set({
            key: "_sensor",
            value: JSON.stringify({
              pass: true,
            }),
          });

          router.push("/home");
        } else {
          // fail authentication
          alert("verification failed...");
        }
      }
    };

    return {
      _fingerHandle,
      fingerPrintOutline,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
.finger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  height: 100%;

  img {
    width: 30px;
  }
  h1 {
    font-size: 24px;
    margin: 30px 0;
  }

  ion-icon {
    margin: 10px 0;
    font-size: 220px;
  }

  &__copy {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: rgba(black, 0.6);
    font-size: 9px;
  }

  ion-item,
  ion-button {
    width: 100%;
  }
}
</style>
